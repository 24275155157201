.other-offerings {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    padding: 48px 0;

    .offerings-row {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
        width: 100%;
        margin-top: 20px;

        .offering {
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-start;
            align-items: center;
            flex: 1 1 auto;
            background: #fff;
            color: #262626;
            max-width: 32%;
            min-height: 230px;
            border-radius: 6px;
            padding: 45px 25px 20px;
            overflow: hidden;
            text-decoration: none;

            img {
                height: 60px;
                margin-bottom: 25px;
            }

            h2 {
                margin-top: 10px;
            }

            h2, p {
                text-align: center;
                width: 100%;
            }

            span {
                margin-top: auto;
            }

            &:not(:last-child) {
                margin-right: 18px;
            }

            .icon {
                height: 60px;
                width: 100%;

                background: {
                    size: contain;
                    position: center;
                    repeat: no-repeat;
                }

                &.deals {
                    background-image: url("./Images/deals.png");
                }

                &.oneclick {
                    background-image: url("./Images/logo-autofact.png");
                }

                &.io {
                    background-image: url("./Images/io.png");
                }

                &.valuation {
                    background-image: url("./Images/valuation.png");
                }

                &.research {
                    background-image: url("./Images/research.png");
                }

                &.safe-point {
                    background-image: url("./Images/secure-transaction.png");
                    background-size: 60%;
                }

                &.premium {
                    background-image: url("./Images/premium.png");
                }

                &.sell-myself {
                    background-image: url("./Images/sell-myself.png");
                }


                &.redbook-valuation {
                    background-image: url("./Images/valuation.png");
                }
                
                &.redbook-bike-valuation {
                    background-image: url("./Images/redbook-bike-valuation.png");
                }
                
                &.carfacts {
                    background-image: url("./Images/carfacts.png");
                    width: 50%;
                }

                &.redbook-car-commercial {
                    background-image: url("./Images/redbook-bike-commercial.png");
                }

                &.redbook-bike-commercial {
                    background-image: url("./Images/redbook-bike-commercial.png");
                }

                &.redbook-inspect {
                    background-image: url("./Images/redbook-inspect.png");
                }

                &.redbook-bike-fact {
                    background-image: url("./Images/redbook-bike-fact.jpg");
                }

                &.redbook-boats-mobile {
                    background-image: url("./Images/redbook-boats-mobile.png");
                }

                &.redbook-boats-fact {
                    background-image: url("./Images/redbook-boats-fact.jpg");
                }

                &.redbook-boats-website {
                    background-image: url("./Images/redbook-boats-website.png");
                }

                &.camping {
                    background-image: url("./Images/camping.png");
                }

                &.caravans {
                    background-image: url("./Images/caravans.png");
                }

                &.offroad {
                    background-image: url("./Images/offroad.png");
                }
            }
        }
    }
}
.redbookcars, .redbookboats, .redbookbikes {
    .other-offerings {
        .offerings-row {
           .offering{
               border: 1px solid #dddddd;
           }
        }
    }
}

.caravancampingsales {
    .other-offerings {
        padding: 20px 0px;

        .offerings-row {
            .offering {
                border: 1px solid #dddddd;
                @media (max-width: 800px) {
                    span {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 120px;
                    }
                }
            }
        }
    }
}